class HomepageHeader {
	block;

	constructor(block) {
		this.block = block;
		this.initialised = false;
		this.imagesNotShown = this.block.getAttribute('data-images').split(',');
		this.imagesCurrentlyShown = this.block.getAttribute('data-existing-images').split(',');
		
		document.addEventListener('mousemove', () => {
			if (this.initialised) return;
			this.initialised = true;
			this.initSingle();
		}, {
			once: true
		});

		document.addEventListener('touchmove', () => {
			if (this.initialised) return;
			this.initialised = true;
			this.initSingle();
		}, {
			once: true
		});
	}

	initSingle(block){
		this.imageContainers = this.block.querySelectorAll('.block-homepage-header__image');
		this.currentImageContainer = 0;

		this.getAllImageHtml();
	}

	incrementCurrentImageContainer() {
		this.currentImageContainer++;
		if (this.currentImageContainer >= this.imageContainers.length) {
			this.currentImageContainer = 0;
		}
		
	}

	async getAllImageHtml() {
		this.imageOrder = this.imagesNotShown.concat(this.imagesCurrentlyShown);
		// console.log(this.imageOrder);
		this.currentImageIndex = 0;

		this.interval = setInterval(() => {
			this.getNextImageHtml();
			this.getNextImageId();
			this.incrementCurrentImageContainer();
		}, 2000);

	}

	getNextImageId() {
		this.currentImageIndex++;
		if (this.currentImageIndex >= this.imageOrder.length) {
			this.currentImageIndex = 0;
		}
		return this.imageOrder[this.currentImageIndex];
	}

	getImageJson(image) {
		const nextImageFound = window.bannerAdditionalImages.find((img) => {
			return img.id === parseInt(image);
		})

		if(nextImageFound) {
			this.swapImageHtml(nextImageFound);
		}
	}

	getNextImageHtml() {
		const imageID = this.imageOrder[this.currentImageIndex];

		// if this image is already in the page, skip this image
		if(this.block.querySelector(`[data-id="${imageID}"]`)) {
			// console.log('skipping image ' + imageID);
			return;
		}

		this.getImageJson(imageID);
	}

	swapImageHtml(newImageData) {
		// console.log(html);
		const image = this.imageContainers[this.currentImageContainer];
		const orientation = image.getAttribute('data-orientation');
		// console.log(orientation);
		const existingImage = image.querySelector('span');
		const existingImageId = existingImage.getAttribute('data-id');
		const newImage = document.createElement('span');
		newImage.setAttribute('data-id', newImageData.id);

		let newImageHtml = newImageData.html;
		if(newImageData?.[orientation]) {
			newImageHtml = newImageData[orientation];
		}

		// console.log(newImageHtml);

		// if the image is a video
		newImage.innerHTML = newImageHtml;

		image.appendChild(newImage);
		setTimeout(() => {
			existingImage.remove();
		}, 1000);

		this.imagesCurrentlyShown.push(this.lastSelectedImage);
		this.imagesNotShown.push(existingImageId);
		// remove the old image from imagesCurrentlyShown and add it to imagesNotShown
		this.imagesCurrentlyShown = this.imagesCurrentlyShown.filter((image) => {
			return image !== existingImageId;
		});

		// remove the new image from imagesNotShown
		this.imagesNotShown = this.imagesNotShown.filter((image) => {
			return image !== this.lastSelectedImage;
		});

	}

}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-homepage-header').forEach((block) => {
		new HomepageHeader(block);
	})
});
